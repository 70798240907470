//我的抄送
<template>
  <Page>
    <template slot="search">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="业务类型" prop="type">
          <el-select v-model="params.type" clearable placeholder="请选择">
            <el-option
              v-for="item in $constant.applyType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </template>
    <div class="table-wrap">
      <el-table border :data="tableData" v-loading="loading">
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="unique"
          label="申请单号"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="typeStr"
          label="业务类型"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="applier.nickname"
          label="申请人"
        />
		<el-table-column
		  header-align="center"
		  align="center"
		  show-overflow-tooltip
		  prop="createTime"
		  label="申请时间"
		>
		  <template slot-scope="{ row }">
		    {{ new Date(row.applyTime) | date('YYYY-mm-dd HH:MM:SS') }}
		  </template>
		</el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="createTime"
          label="抄送时间"
        >
          <template slot-scope="{ row }">
            {{ new Date(row.createTime) | date('YYYY-mm-dd HH:MM:SS') }}
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="auditStatusStr"
          label="审批状态"
        />
        <el-table-column
          header-align="center"
          show-overflow-tooltip
          label="操作"
          width="150"
        >
          <template slot-scope="{ row }">
            <el-button
              size="medium"
              type="text"
              v-auth="'detail'"
              @click="toDetail(row)"
            >
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.pageNo"
      @change="getData"
    />
  </Page>
</template>

<script>
import watchParamsGetData from '../../mixins/watchParamsGetData'
export default {
  components: {},
  data() {
    return {
      confirmVisible: false,
      params: { status: '' }
    }
  },
  mixins: [watchParamsGetData],
  methods: {
    async getData() {
      try {
        this.loading = true
        let params = { ...this.params }
        const { list, total } = await this.$api.workOrder.getMyCCList(params)
        this.tableData = (list || []).map(item => {
          const auditStatusStr = this.$constant.auditStatus.find(
            _ => _.value == item.status
          )?.label
          const typeStr = this.$constant.applyType.find(
            _ => _.value == item.attach
          )?.label
          return { ...item, auditStatusStr, typeStr }
        })
        this.total = total
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async toDetail(row) {
      let res = await this.$api.apply.applyResult({
        processCode: row.unique
      })
      if (res) {
        this.$router.push({
          path: '/myAudit/detail',
          query: {
            orderCode: row.unique,
            processInstanceId: row.processInstanceId,
            type: row.attach,
            from: 'myCC'
          }
        })
      }
    }
  }
}
</script>
